@import '~antd/dist/antd.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#components-layout-demo-custom-trigger {
  background-color: #ccc;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-background {
  background: #fff;
}

.site-layout {
  background: #eee;
  height: 100vh;
}

.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  overflow: auto;
}

.steps-action {
  margin-top: 24px;
}

.sortable-item {
  pointer-events: auto !important;
  cursor: grab;
}

.sortable-container > .sortable-item:hover {
  cursor: grab;
}

body > .sortable-item:hover {
  cursor: grabbing;
}
@primary-color: #374364;